import React from "react";

import Layout from '../components/layout';
import Stage  from "../components/molecules/stage/stage";
import Seo    from "../components/atoms/seo/seo";
import Agb    from "../components/molecules/modalContent/Agb";

export default () => (
  <>
    <Seo
      title="AGB"
      canonical="agb"
    />
    <Layout>
      <Stage>
        <Agb />
      </Stage>
    </Layout>
  </>
)
